<template>
  <v-row class="mb-16">
    <!-- header -->
    <mini-header :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true">
      <template v-slot:main>
        <v-btn class="ml-2" @click="dialogConfirmPauseCampaign=true" outlined>
          <v-icon left>mdi-pause-circle </v-icon> Pausar campaña
        </v-btn>
        <v-btn class="ml-2" :to="{name: 'StatisticsCampaignList', params: { id: 'ca_qEmqLQwAHuU3R5RrK4' }}" outlined>
          <v-icon left>mdi-chart-timeline-variant </v-icon> Estadísticas
        </v-btn>
        <v-btn class="ml-2" @click="$router.push({ name: 'EditTemplate', params: { id: 'template-1'}})" color="primary">
          <v-icon left>mdi-sync </v-icon>Reutilizar campaña
        </v-btn>
      </template>
    </mini-header>

    <!-- end header -->
    <v-col cols="8" class="pa-0 pb-10 mx-auto">
      <v-card class="mb-16" flat>
        <v-card-text class="px-4 py-5 overflow-y-auto" :style="`max-height: ${$vuetify.breakpoint.height <= 720 ? '460px' : 'calc(100vh - 260px)'} !important;`">
          <ModelTemplates
            currentTemplate="template-1"
            :logo="logo"
            :image="image"
            :btn="btn"
            :text="text"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <!-- dialog pause campaign confirm -->
    <v-dialog v-model="dialogConfirmPauseCampaign" width="838" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Pausar campaña</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogConfirmPauseCampaign=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <v-row class="pt-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-send-campaing.svg`)" :width="188" />
            </v-col>
            <v-col class="pr-4 body-1 pl-6">
              <span class="d-block body-1">Estás a punto de <span class="font-weight-semibold">pausar esta campaña.</span>Esto quiere decir que se <span class="font-weight-semibold">dejara de enviar la plantilla junto al documento seleccionado. </span></span>
              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />

              <!-- alert -->
              <v-alert class="inf-alert-blue primary--text rounded-md mt-4" :height="50" dense text>
                <div class="d-flex pa-0 my-1 pt-1">
                  <v-icon color="primary" size="22">mdi-information-outline</v-icon>
                  <div class="ml-4">
                    <span class="body-2 d-flex align-center" style="line-height: 14px;">Esta acción
                      <span class="font-weight-bold">podría tardar varios minutos</span> en completarse.
                    </span>
                  </div>
                </div>
              </v-alert>
              <!-- end alert -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogConfirmPauseCampaign=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="dialogConfirmPauseCampaign=false" :disabled="confirm.toLowerCase() !== 'confirmar'">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog pause campaign -->
  </v-row>
</template>
<script>
import MiniHeader from '@/components/commons/MiniHeader'
import ModelTemplates from '@/modules/outbound/templates/components/templates/Template1'
export default {
  components: {
    MiniHeader,
    ModelTemplates
  },
  data: () => ({
    confirm: '',
    dialogConfirmPauseCampaign: false,
    logo: {
      file: 'https://seeklogo.com/images/P/plazavea-logo-B92221BCD7-seeklogo.com.png',
      hiddenLogo: false,
      color: '#FFFFFF'
    },
    imag: {
      hiddenBorder: false,
      borderWidth: '#FFFFFF',
      borderRadius: '',
      borderColor: '#FFFFFF'
    },
    text: {
      fontColorTitle: '#E20611',
      fontColorSubtitle: '#000000',
      fontColorParagraph: '#000000'
    },
    btn: {
      fontFamily: '',
      fontColor: '#FFFFF',
      hiddenBorder: false,
      hiddenBackgroundColor: false,
      backgroundColor: '#E20611',
      borderWidth: '',
      borderRadius: '',
      borderColor: '#FFFFFF',
      align: ''
    },
    breadcrumbs: {
     main: 'Plantillas',
     children: [{ text: ''}]
   }
  }),
  created () {
   this.breadcrumbs.children[0].text = this.$route.params.id
  }
}
</script>